$(function() {
	'use strict';
	
	$(document).ready(function() {
	 $('body').fadeIn(2000);
	 $('#bar').delay(1000).fadeIn(2000);
	});
	
	(function () {
    $('a[href^="#"]:not([href="#"])').click(function () {
      var target, targetOffset;

      jQuery.easing.quart = function (x, t, b, c, d) {
        return -c * ((t = t / d - 1) * t * t * t - 1) + b;
      };
      if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
        target = $(this.hash);
        target = (target.length && target) || $('[name="' + this.hash.slice(1) + '"]');
        if (target.length) {
          targetOffset = target.offset().top;
          $('html,body').animate({ scrollTop: targetOffset }, 'normal', 'quart');
          return false;
        }
      }
    });
  }());
  
  
  $(function() {
	  $('.section .container').css("opacity","0");
	  $(window).scroll(function (){
	    $(".section").each(function(){
	      var Pos = $(this).offset().top;    
	      var scroll = $(window).scrollTop();
	      var wHeight = $(window).height();
	      if (scroll > Pos - wHeight + wHeight/3){
	        $(".container",this).css("opacity","1");
	      } else {
	        $(".container",this).css("opacity","0");
	      }
	    });
	  });
	});
	
	
  $(window).scroll(function(){
		if( $(window).scrollTop() + $(window).height() > 1800){
			$('#pagetop').css("opacity","1");
		} else {
			$('#pagetop').css("opacity","0");
		}
	});

});